require('bootstrap/js/dist/popover');
require('jquery-ui/ui/widgets/autocomplete.js');
require('jquery-ui/ui/widgets/sortable.js');

$(document).ready(function () {
	$(".sortable-articles").hover(function () {
		if ($(".sortable-articles").find("li").length <= 1) {
			$(".ui-sortable-handle").css('cursor', 'default', 'important');
		}
	});

	$(".sortable").hover(function () {
		if ($(".sortable").find("li").length <= 1) {
			$(".ui-sortable-handle").css('cursor', 'default', 'important');
		}
	});

	// js for institution on resource page
	$(".journal_add_inst").hide();

	$(".inst_addition").on("click", ".plus_inst", function () {
		$(".journal_add_inst").toggle();
	});

	$(".inst_add_btn").on("click", function () {
		$(".inst_add").toggle();
		$.ajax({
			type: 'GET',
			url: '/institutions',
			dataType: 'json',
			success: function (data) {
				$("#journal_institution").empty();
				$.each(data, function (key, value) {
					$("#journal_institution").append("<option>" + value["name"] + "</option>")
				});
			}
		});
	});
	// end js for inst. on resource page


	//journal add on journal issue page
	$(".journal_ji_add").hide();

	$(".plus_journal").on("click", function () {
		$(".journal_ji_add").toggle();
	});

	//end journal add on journal issue page

	//attachments on resource page
	$('#attach-input-wrapper').on('click', '#add-another-attach', function () {
		var $roleInputField = $(this).siblings('.attach-input-field').first();
		var currentNumberOfRoleElements = $('#attach-input-wrapper').find('.attach-input-field').length;

		var $clonedElement = $roleInputField.clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying

		$($clonedElement).children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, currentNumberOfRoleElements);
			}
		});


		$(this).after($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
	});

	$('#edit-attachments-wrapper').on('click', '#add-another-attach', function () {
		var $roleInputField = $(this).siblings('.attach-input-field').first();
		var currentNumberOfRoleElements = $('#edit-attachments-wrapper').find('.attach-input-field').length;

		var $clonedElement = $roleInputField.clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying

		$($clonedElement).children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, currentNumberOfRoleElements);
			}
		});


		$(this).after($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
	});
	//end attachments on resource page

	//autocomplete scripts

	if ($("body").hasClass("blacklight-resources-edit") || $("body").hasClass("blacklight-journals-edit")) {
		var inst_name = $(".inst_auto").siblings(".journal_add_inst").find("input").first().val();
		$(".inst_auto").val(inst_name);
	}

	if ($("body").hasClass("blacklight-resources-edit")) {
		var res_name = $(".parent_auto").siblings(".parent_auto_name").val();
		$(".parent_auto").val(res_name);
		var jrnl_name = $(".journal_auto_name").val();
		$(".journal_auto").val(jrnl_name);
	}

	$(".auth_auto").autocomplete({
		source: '/autocomplete/author',
		minLength: 2,
		select: function (event, ui) {
			$(".auth_auto_id").val(ui.item.id);
		}

	});

	$(".inst_auto").autocomplete({
		source: '/autocomplete/institution',
		minLength: 2,
		select: function (event, ui) {
			$(".auto_inst_id").val(ui.item.id);
		}
	});

	$(".location_auto").autocomplete({
		source: '/autocomplete/location',
		minLength: 2,
		select: function (event, ui) {
			$(".location_auto_id").val(ui.item.id);
		}
	});

	$(".topic_auto").autocomplete({
		source: '/autocomplete/topic',
		minLength: 2,
		select: function (event, ui) {
			$(".topic_auto_id").val(ui.item.id);
		}
	});

	$(".parent_auto").autocomplete({
		source: '/autocomplete/resource',
		minLength: 2,
		select: function (event, ui) {
			$(".parent_auto_id").val(ui.item.id);
		}
	});

	$(".journal_auto").autocomplete({
		source: '/autocomplete/journal',
		minLength: 2,
		select: function (event, ui) {
			$(".journal_auto_id").val(ui.item.id);
			var id = $(".journal_auto_id").val();
			$.ajax({
				type: 'GET',
				url: '/journals/issues',
				dataType: 'JSON',
				data: { id: id },
				success: function (data) {
					var option;
					var special;
					$.each(data, function (index, value) {
						if (value.special_date != "" && value.special_date != undefined && value.volume) {
							special = "<option value=" + value.id + ">" + value.special_date + "</option>";
							option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
						} else if (value.special_date != "" && value.special_date != undefined) {
							special = "<option value=" + value.id + ">" + value.special_date + "</option>";
						} else if (value.volume) {
							option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
						} else {

						}
						$(option).appendTo(".j-issues");
						$(special).appendTo(".j-special");
					});
				}
			});
		}
	});

	$(".journal_auto_form").autocomplete({
		source: '/autocomplete/journal',
		minLength: 2,
		select: function (event, ui) {
			$(".journal_auto_id").val(ui.item.id);
			var id = $(".journal_auto_id").val();
		}
	});

	$(".section_auto").autocomplete({
		source: '/autocomplete/section',
		minLength: 2
	});

	//other js
	$(".rmv-jrnl-inst").on("click", function () {
		$(this).remove();
	});

	$("#remove-inst").bind("ajax:complete", function (event, xhr, status) {
		var $clonedElement = $(".inst_addition").clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying

		$($clonedElement).children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
			}
		});

		$($clonedElement).children().children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
				$(this).val("");
			}
		});

		$($clonedElement).children().children().children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
				$(this).val("");
			}
		});

		$(".inst_addition").remove();
		$(".journal_add_inst").find("input[type=text], textarea").val(" ")

		$("#resource_abstract").after($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
		$(".inst_auto").autocomplete({
			source: '/autocomplete/institution',
			minLength: 2,
			select: function (event, ui) {
				$(".auto_inst_id").val(ui.item.id);
			}
		});
		$(".inst_addition").on("click", ".plus_inst", function () {
			$(".journal_add_inst").toggle();
		});

	});

	$("#remove-inst-jrnl").bind("ajax:complete", function (event, xhr, status) {
		var $clonedElement = $(".inst_addition").clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying

		$($clonedElement).children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
			}
		});

		$($clonedElement).children().children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
				$(this).val("");
			}
		});

		$($clonedElement).children().children().children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
				$(this).val("");
			}
		});

		$(".inst_addition").remove();
		$(".journal_add_inst").find("input[type=text], textarea").val(" ")

		$("#edit-pub_id-wrapper").after($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
		$(".inst_auto").autocomplete({
			source: '/autocomplete/institution',
			minLength: 2,
			select: function (event, ui) {
				$(".auto_inst_id").val(ui.item.id);
			}
		});
		$(".inst_addition").on("click", ".plus_inst", function () {
			$(".journal_add_inst").toggle();
		});

	});

	$("#remove-parent").bind("ajax:complete", function (event, xhr, status) {
		var $clonedElement = $(".parent-resource-affil").clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying

		$($clonedElement).children().each(function () {
			if (typeof (this.name) != "undefined") {
				this.name = this.name.replace(0, 1);
			}
		});

		$(".parent-resource-affil").remove();
		$(".parent-resource-affil").find("input[type=text]").val(" ");

		$(".content_type-form").after($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
		$(".parent_auto").autocomplete({
			source: '/autocomplete/resource',
			minLength: 2,
			select: function (event, ui) {
				$(".parent_auto_id").val(ui.item.id);
			}
		});

	});

    let removeClosestDataId = function() {
    	removeClosest(this, "[data-id]");
    }
    let removeParent = function() {
    	$(this).parent().remove();
    }

	$(".topics-list").on("click", ".remove-topic", removeClosestDataId);

	$(".locations-list").on("click", ".remove-location", removeClosestDataId);

	$(".remove-featured-image").on("click", removeParent);

	$(".unfeature-res").on("click", removeParent);

	$(".delete-res").on("click", removeParent);

	$(".unpublish-res").on("click", removeParent);

	$(".remove-attachment").on("click", removeParent);

	$(".remove-externalres").on("click", removeParent);

	$(".pub-list").on("click", ".remove-pub_id", function () {
		removeClosest(this, ".pub_id");
	});

	$(".author_listing").on("click", ".remove-role", removeClosestDataId);

	$(".remove-journal-issue").bind("ajax:complete", function (event, xhr, status) {
		$(".res-j-issue").empty();
		var $clonedElement = $(".journal-issue-wrapper").clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying
		$(".journal-issue-wrapper").remove();


		$(".journal-issue-main").append($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
		$(".journal_auto").autocomplete({
			source: '/autocomplete/journal',
			minLength: 2,
			select: function (event, ui) {
				$(".journal_auto_id").val(ui.item.id);
				var id = $(".journal_auto_id").val();
				$.ajax({
					type: 'GET',
					url: '/journals/issues',
					dataType: 'JSON',
					data: { id: id },
					success: function (data) {
						var option;
						var special;
						$.each(data, function (index, value) {
							if (value.special_date != "" && value.special_date != undefined && value.volume) {
								special = "<option value=" + value.id + ">" + value.special_date + "</option>";
								option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
							} else if (value.special_date != "" && value.special_date != undefined) {
								special = "<option value=" + value.id + ">" + value.special_date + "</option>";
							} else if (value.volume) {
								option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
							} else {

							}
							$(option).appendTo(".j-issues");
							$(special).appendTo(".j-special");
						});
					}
				});
			}
		});

	});

	$(".journal-issue-wrapper").on("click", ".j-iss-remove", function () {
		$(".res-j-issue").empty();
		var $clonedElement = $(".journal-issue-wrapper").clone(false); // Make a copy of the element, but do not copy element data and events (using false argument).
		$clonedElement.find('input').val(''); // Clear a potentially existing value in the input that we're copying
		$(".journal-issue-wrapper").remove();


		$(".journal-issue-main").append($clonedElement); // Append the new cloned element to the end of the .role-input-wrapper element
		$(".journal_auto").autocomplete({
			source: '/autocomplete/journal',
			minLength: 2,
			select: function (event, ui) {
				$(".journal_auto_id").val(ui.item.id);
				var id = $(".journal_auto_id").val();
				$.ajax({
					type: 'GET',
					url: '/journals/issues',
					dataType: 'JSON',
					data: { id: id },
					success: function (data) {
						var option;
						var special;
						$.each(data, function (index, value) {
							if (value.special_date != "" && value.special_date != undefined && value.volume) {
								special = "<option value=" + value.id + ">" + value.special_date + "</option>";
								option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
							} else if (value.special_date != "" && value.special_date != undefined) {
								special = "<option value=" + value.id + ">" + value.special_date + "</option>";
							} else if (value.volume) {
								option = "<option value=" + value.id + ">Volume: " + value.volume + ", Issue: " + value.issue_number + "</option>";
							} else {

							}
							$(option).appendTo(".j-issues");
							$(special).appendTo(".j-special");
						});
					}
				});
			}
		});

	});

	// drag and drop ordering


	$(".sortable").sortable({
		update: function (event, ui) {
			var id = $(this).attr("id");
			var itm_arr = $(".sortable").sortable('toArray');
			var pobj = { orders: itm_arr };
			$.ajax({
				type: 'POST',
				url: '/resources/reorder',
				dataType: 'JSON',
				data: { orders: JSON.stringify(itm_arr), id: id }
			});
		}

	});

	$(".sortable-articles").sortable({
		update: function (event, ui) {
			var id = $(this).attr("id");
			var itm_arr = $(".sortable-articles").sortable('toArray');
			var pobj = { orders: itm_arr };
			$.ajax({
				type: 'POST',
				url: '/journal_issues/reorder_articles',
				dataType: 'JSON',
				data: { orders: JSON.stringify(itm_arr), id: id }
			});
		}

	});

	//hiding journal on resource page unless select plus pub id
	$(".j-info").insertAfter($("#j-issue-header"));
	$(".res-j-issue").insertAfter($("#j-issue-header"));

	$(".journal-issue-wrapper").hide();
	if ($("#resource_content_type_id option:selected").text() == "Journal Article") {
		$(".journal-issue-wrapper").show();
		$(".inst_addition").hide();
		$(".res-pub-date").hide();
		$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").hide();
	}

	if ($("#resource_content_type_id option:selected").text() == "Book") {
		$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").show();
	} else {
		$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").hide();
	}

	$('#resource_content_type_id').on('change', function () {
		if ($('#resource_content_type_id option:selected').text() == "Journal Article") {
			$(".journal-issue-wrapper").show();
			$(".inst_addition").hide();
			$(".res-pub-date").hide();
			$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").hide();
		} else if ($('#resource_content_type_id option:selected').text() == "Book") {
			$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").show();
			$(".journal-issue-wrapper").hide();
			$(".journal-issue-wrapper").children().each(function () {
				$(this).val("");
			});
			$(".journal-issue-wrapper").children().children().each(function () {
				$(this).val("");
			});
		} else {
			$(".journal-issue-wrapper").hide();
			$(".inst_addition").show();
			$(".res-pub-date").show();
			$("#resource_publication_identifiers_attributes_0_kind option[value=ISBN]").hide();
			$(".journal-issue-wrapper").children().each(function () {
				$(this).val("");
			});
			$(".journal-issue-wrapper").children().children().each(function () {
				$(this).val("");
			});
		}
	});

	$('#resource_content_type_id').on('change', function () {
		if ($('#resource_content_type_id option:selected').text() == "Course Pack" || $('#resource_content_type_id option:selected').text() == "Case Study" || $('#resource_content_type_id option:selected').text() == "CIAO Focus") {
			$(".parent-resource-affil").hide();
		} else {
			$(".parent-resource-affil").show();
		}
	});

	if ($('#resource_content_type_id option:selected').text() == "Course Pack" || $('#resource_content_type_id option:selected').text() == "Case Study" || $('#resource_content_type_id option:selected').text() == "CIAO Focus") {
		$(".parent-resource-affil").hide();
	} else {
		$(".parent-resource-affil").show();
	}




	// dashboard select
	$(".create_select_go").on("click", function () {
		var selected = $("select#create_options").val().toLowerCase();
		window.location.replace('/' + selected + 's/new');
	});

	// disable author add button unless role selected
	$(".author-addition-new").attr("disabled", true);
	$(".author-addition").attr("disabled", true);

	$(".role_name").on("change", function () {
		if ($(".role_name option:selected").text() == " ") {
			$(".author-addition-new").attr("disabled", true);
			$(".author-addition").attr("disabled", true);
		} else {
			$(".author-addition-new").attr("disabled", false);
			$(".author-addition").attr("disabled", false);
		}
	})
	// authors add on edit resource page
	$(".author-addition").on("click", function (event) {
		event.preventDefault();
		if (!$(".auth_auto_id").val()) {
			var name = $(".auth_auto").val();
			var last = name.split(",")[0];
			var first = name.split(",")[1];
			var resource_id = $(".role-input-wrapper").attr("data-id");
			var role_name = $(".role_name option:selected").text();
			$.ajax({
				type: 'POST',
				url: '/authors',
				dataType: 'JSON',
				data: { author: { first_name: first, last_name: last } },
				success: function (data) {
					var authorsId = data.id;
					$.ajax({
						type: 'POST',
						url: '/roles',
						dataType: 'JSON',
						data: { role: { author_id: data.id, resource_id: resource_id, name: role_name } },
						success: function (data) {
							$("<li data-id='" + authorsId + "'><dt>" + role_name + ": </dt><dd>" + name + "</dd> <a class='remove-role text-danger' data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_role?role=" + data.id + "'><span class='fa fa-remove'><span></a> <a data-remote='true' href='/authors/" + authorsId + "/edit'><i class='fa fa-edit'></i></a></li>").appendTo(".author_listing");
							$(".auth_auto_id").val("");
							$(".auth_auto").val("");
							$(".role_name").val(" ");
							$(".author-addition-new").attr("disabled", true);
							$(".author-addition").attr("disabled", true);
						}
					});
				}
			});
		} else {
			var thisId = $(".auth_auto_id").val();
			var name = $(".auth_auto").val();
			var resource_id = $(".role-input-wrapper").attr("data-id");
			var role_name = $(".role_name option:selected").text();
			$.ajax({
				type: 'POST',
				url: '/roles',
				dataType: 'JSON',
				data: { role: { author_id: thisId, resource_id: resource_id, name: role_name } },
				success: function (data) {
					$("<li data-id='" + thisId + "'><dt>" + role_name + ": </dt><dd>" + name + "</dd> <a class='remove-role text-danger' data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_role?role=" + data.id + "'><span class='fa fa-remove'><span></a> <a data-remote='true' href='/authors/" + thisId + "/edit'><i class='fa fa-edit'></i></a></li>").appendTo(".author_listing");
					$(".auth_auto_id").val("");
					$(".auth_auto").val("");
					$(".role_name").val(" ");
					$(".author-addition-new").attr("disabled", true);
					$(".author-addition").attr("disabled", true);
				}
			});
		}
	});

	//authors add on new resource page
	var role_auth_num = 1;
	$(".author-addition-new").on("click", function (event) {
		event.preventDefault();
		var thisId = $(".auth_auto_id").val();
		var name = $(".auth_auto").val();
		var last = name.split(",")[0];
		var first = name.split(",")[1]
		var role_name = $(".role_name option:selected").text();
		if (!$(".auth_auto_id").val()) {
			$.ajax({
				type: 'POST',
				url: '/authors',
				dataType: 'JSON',
				data: { author: { first_name: first, last_name: last } },
				success: function (data) {
					$("<li data-id='" + data.id + "'><dt>" + role_name + ": </dt><dd>" + name + "</dd> <a class='remove-role text-danger' style='display:inline'><span class='fa fa-remove'><span></a> <a data-remote='true' href='/authors/" + data.id + "/edit'><i class='fa fa-edit'></i></a></li>").appendTo(".author_listing").append("<input type='hidden' name='resource[roles_attributes][" + role_auth_num + "][author_id]' id='resource_attributes_0_author_id' value='" + data.id + "'>").append("<input type='hidden' name='resource[roles_attributes][" + role_auth_num + "][name]' id='resource_attributes_0_name' value='" + role_name + "'>");
					$(".auth_auto_id").val("");
					$(".auth_auto").val("");
					$(".role_name").val(" ");
					$(".author-addition-new").attr("disabled", true);
					$(".author-addition").attr("disabled", true);
					role_auth_num++;
				}
			});
		} else {
			$("<li data-id='" + thisId + "'><dt>" + role_name + ": </dt><dd>" + name + "</dd> <a class='remove-role text-danger' style='display:inline'><span class='fa fa-remove'><span></a> <a data-remote='true' href='/authors/" + thisId + "/edit'><i class='fa fa-edit'></i></a></li>").appendTo(".author_listing").append("<input type='hidden' name='resource[roles_attributes][" + role_auth_num + "][author_id]' id='resource_attributes_0_author_id' value='" + thisId + "'>").append("<input type='hidden' name='resource[roles_attributes][" + role_auth_num + "][name]' id='resource_attributes_0_name' value='" + role_name + "'>");
			$(".auth_auto_id").val("");
			$(".auth_auto").val("");
			$(".role_name").val(" ");
			$(".author-addition-new").attr("disabled", true);
			$(".author-addition").attr("disabled", true);
			role_auth_num++;
		}

	});

	//topic on edit resource page
	$(".add-topic").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var theId = $(".topic_auto_id").val();
		var name = $(".topic_auto").val();
		if (!$(".topic_auto_id").val()) {
			$.ajax({
				type: 'POST',
				url: '/topics',
				dataType: 'JSON',
				data: { topic: { name: name } },
				success: function (data) {
					$("<li data-id='" + data.id + "'><dt>" + name + "</dt><dd> <a class='remove-topic text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_topic?topic=" + data.id + "'><span class='fa fa-remove'></span></a><a data-remote='true' href='/topics/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".topics-list").append("<input type='hidden' name='resource[topic_ids][]' value='" + data.id + "'>");
					$(".topic_auto_id").val("");
					$(".topic_auto").val("");
				}
			});
		} else {
			$("<li data-id='" + theId + "'><dt>" + name + "</dt><dd> <a class='remove-topic text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_topic?topic=" + theId + "'><span class='fa fa-remove'></span></a><a data-remote='true' href='/topics/" + theId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".topics-list").append("<input type='hidden' name='resource[topic_ids][]' value='" + theId + "'>");
			$(".topic_auto_id").val("");
			$(".topic_auto").val("");
		}
	});

	//topic on new resource page
	$(".add-topic-new").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var theId = $(".topic_auto_id").val();
		var name = $(".topic_auto").val();
		if (!$(".topic_auto_id").val()) {
			$.ajax({
				type: 'POST',
				url: '/topics',
				dataType: 'JSON',
				data: { topic: { name: name } },
				success: function (data) {
					$("<li data-id='" + data.id + "'><dt>" + name + "</dt><dd> <a class='remove-topic text-danger' style='display:inline'><span class='fa fa-remove'></span></a><a data-remote='true' href='/topics/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".topics-list").append("<input type='hidden' name='resource[topic_ids][]' id='resource_topic_ids_' value='" + data.id + "'>");
					$(".topic_auto_id").val("");
					$(".topic_auto").val("");
				}
			});
		} else {
			$("<li data-id='" + theId + "'><dt>" + name + "</dt><dd><a class='remove-topic text-danger' style='display:inline'><span class='fa fa-remove'></span></a><a data-remote='true' href='/topics/" + theId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".topics-list").append("<input type='hidden' name='resource[topic_ids][]' id='resource_topic_ids_' value='" + theId + "'>");
			$(".topic_auto_id").val("");
			$(".topic_auto").val("");
		}
	});

	//location on edit resource page
	$(".add-location").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var theId = $(".location_auto_id").val();
		var name = $(".location_auto").val();
		if (!$(".location_auto_id").val()) {
			$.ajax({
				type: 'POST',
				url: '/locations',
				dataType: 'JSON',
				data: { location: { name: name } },
				success: function (data) {
					$("<li data-id='" + data.id + "'><dt>" + name + "</dt><dd> <a class='remove-location text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_location?location=" + data.id + "'><span class='fa fa-remove'></span></a><a data-remote='true' href='/locations/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".locations-list").append("<input type='hidden' name='resource[location_ids][]' value='" + data.id + "'>");
					$(".location_auto_id").val("");
					$(".location_auto").val("");
				}
			});
		} else {
			$("<li data-id='" + theId + "'><dt>" + name + "</dt><dd> <a class='remove-location text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_location?location=" + theId + "'><span class='fa fa-remove'></span></a><a data-remote='true' href='/locations/" + theId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".locations-list").append("<input type='hidden' name='resource[location_ids][]' value='" + theId + "'>");
			$(".location_auto_id").val("");
			$(".location_auto").val("");
		}
	});

	//locations on new resource page

	$(".add-location-new").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var theId = $(".location_auto_id").val();
		var name = $(".location_auto").val();
		if (!$(".location_auto_id").val()) {
			$.ajax({
				type: 'POST',
				url: '/locations',
				dataType: 'JSON',
				data: { location: { name: name } },
				success: function (data) {
					$("<li data-id='" + data.id + "'><dt>" + name + "</dt><dd> <a class='remove-location text-danger' style='display:inline'><span class='fa fa-remove'></span></a><a data-remote='true' href='/locations/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".locations-list").append("<input type='hidden' name='resource[location_ids][]' id='resource_location_ids_' value='" + data.id + "'>");
					$(".location_auto_id").val("");
					$(".location_auto").val("");
				}
			});
		} else {
			$("<li data-id='" + theId + "'><dt>" + name + "</dt><dd> <a class='remove-location text-danger' style='display:inline'><span class='fa fa-remove'></span></a><a data-remote='true' href='/locations/" + theId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".locations-list").append("<input type='hidden' name='resource[location_ids][]' id='resource_location_ids_' value='" + theId + "'>");
			$(".location_auto_id").val("");
			$(".location_auto").val("");
		}
	});
	// external resources

	$(".external_res_choice").on("change", function () {
		if ($(".external_res_choice option:selected").text() == "YouTube") {
			$(".extrnl_url").attr('placeholder', "Enter YouTube video ID, which occurs after ?v= in the YouTube URL");
		} else {
			$(".extrnl_url").attr('placeholder', "ex. http://www.google.com");
		}
	});

	//pub ids edit page
	$("#add-another-pub_id").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var selection = $(".pub_selection option:selected").text();
		var name = $(".pub_identifier").val();
		$.ajax({
			type: 'POST',
			url: '/publication_identifiers',
			dataType: 'JSON',
			data: { publication_identifier: { identifier: name, kind: selection, resource_id: resource_id } },
			success: function (data) {
				$("<li class='pub_id' data-id='" + data.id + "'><dt>" + selection + ": " + name + "</dt><dd> <a class='remove-pub_id text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_pub_id?publication_identifier=" + data.id + "'><span class='fa fa-times-circle'></span></a><a data-remote='true' href='/publication_identifiers/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".pub-list").append("<input type='hidden' name='resource[publication_identifier_ids][]' id='resource_pub_ids_' value='" + data.id + "'>");
				$(".pub_identifier").val("");
				$(".pub_selection").val("");
			}
		});

	});

	$("#add-another-pub_id-jrnl").on("click", function () {
		var resource_id = $("#edit-pub_id-wrapper").attr("data-id");
		var selection = $(".pub_selection option:selected").text();
		var name = $(".pub_identifier").val();
		$.ajax({
			type: 'POST',
			url: '/publication_identifiers',
			dataType: 'JSON',
			data: { publication_identifier: { identifier: name, kind: selection, resource_id: resource_id } },
			success: function (data) {
				$("<li class='pub_id' data-id='" + data.id + "'><dt>" + selection + ": " + name + "</dt><dd> <a class='remove-pub_id text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/journals/" + resource_id + "/remove_pub_id?publication_identifier=" + data.id + "'><span class='fa fa-times-circle'></span></a><a data-remote='true' href='/publication_identifiers/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".pub-list").append("<input type='hidden' name='journal[publication_identifier_ids][]' id='journal_pub_ids_' value='" + data.id + "'>");
				$(".pub_identifier").val("");
				$(".pub_selection").val("");
			}
		});

	});

	//pub ids on new page
	var pub_id_num = 1
	$("#add-another-publication_id").on("click", function () {
		var selection = $(".pub_selection option:selected").text();
		var name = $(".pub_identifier").val();
		$("<li class='pub_id'>" + selection + ": " + name + " <a class='remove-pub_id text-danger' style='display:inline'><span class='fa fa-times-circle'></span> </a></li>").appendTo(".pub-list").append("<input type='hidden' name='resource[publication_identifiers_attributes][" + pub_id_num + "][identifier]' id='resource_publication_identifiers_attributes_0_identifier' value='" + name + "'>").append("<input type='hidden' name='resource[publication_identifiers_attributes][" + pub_id_num + "][kind]' id='resource_publication_identifiers_attributes_0_kind' value='" + selection + "'>");
		$(".pub_identifier").val("");
		// $(".pub_selection").val("ISSN");
		pub_id_num++;

	});

	var pub_id_num_jrnl = 1
	$("#add-another-publication_id-jrnl-new").on("click", function () {
		var selection = $(".pub_selection option:selected").text();
		var name = $(".pub_identifier").val();
		$("<li class='pub_id'>" + selection + ": " + name + " <a class='remove-pub_id text-danger' style='display:inline'><span class='fa fa-times-circle'></span> </a></li>").appendTo(".pub-list").append("<input type='hidden' name='journal[publication_identifiers_attributes][" + pub_id_num_jrnl + "][identifier]' id='journal_publication_identifiers_attributes_0_identifier' value='" + name + "'>").append("<input type='hidden' name='journal[publication_identifiers_attributes][" + pub_id_num_jrnl + "][kind]' id='journal_publication_identifiers_attributes_0_kind' value='" + selection + "'>");
		$(".pub_identifier").val("");
		// $(".pub_selection").val("ISSN");
		pub_id_num_jrnl++;

	});

	//external res on edit page
	$("#add-another-extrnl").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var selection = $(".external_res_choice option:selected").text();
		var name = $(".ext_name").val();
		var location = $(".extrnl_url").val();
		$.ajax({
			type: 'POST',
			url: '/external_resources',
			dataType: 'JSON',
			data: { external_resource: { name: name, kind: selection, url: location, resource_id: resource_id } },
			success: function (data) {
				$("<li data-id='" + data.id + "'><dt>" + name + " </dt><dd><a class='remove-location text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_externalres?externalres=" + data.id + "'><span class='fa fa-times-circle'></span></a><a data-remote='true' href='/external_resources/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".ext-list").append("<input type='hidden' name='resource[external_resource_ids][]' id='resource_external_resource_ids_' value='" + data.id + "'>");
				$(".ext_name").val("");
				$(".extrnl_url").val("");
				$(".external_res_choice").val("Link");
			}
		});

	});
	//external res on new page
	$("#add-another-extrnl-res").on("click", function () {
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var selection = $(".external_res_choice option:selected").text();
		var name = $(".ext_name").val();
		var location = $(".extrnl_url").val();
		$.ajax({
			type: 'POST',
			url: '/external_resources',
			dataType: 'JSON',
			data: { external_resource: { name: name, kind: selection, url: location, resource_id: resource_id } },
			success: function (data) {
				$("<li data-id='" + data.id + "'><dt>" + name + " </dt><dd><a class='remove-location text-danger'  data-remote='true' rel='nofollow' data-method='post' href='/resources/" + resource_id + "/remove_externalres?externalres=" + data.id + "'><span class='fa fa-times-circle'></span></a><a data-remote='true' href='/external_resources/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".ext-list").append("<input type='hidden' name='resource[external_resource_ids][]' id='resource_external_resource_ids_' value='" + data.id + "'>");
				$(".ext_name").val("");
				$(".extrnl_url").val("");
				$(".external_res_choice").val("Link");
			}
		});

	});
	//journal issue on new page
	$(".journal-issue-main").on("click", ".jissue-addition-new", function () {
		var journal_id_original = $(".journal_auto_id").val();
		var journal_name = $(".journal_auto").val();
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var volume = $("#resource_journal_issue_attributes_volume").val();
		var issue_number = $("#resource_journal_issue_attributes_issue_number").val();
		var date = $("#resource_journal_issue_attributes_date_1i").val() + "/" + $("#resource_journal_issue_attributes_date_2i").val() + "/" + $("#resource_journal_issue_attributes_date_3i").val();
		var special_date = $("#resource_journal_issue_attributes_special_date").val();
		var url = $("#resource_journal_issue_attributes_url").val();
		if (journal_id_original == "") {
			$.ajax({
				type: 'POST',
				url: '/journals',
				dataType: 'JSON',
				data: { journal: { name: journal_name } },
				success: function (data) {
					$(".journal_auto_id").val(data.id);
					var journal_id = $(".journal_auto_id").val();
					$.ajax({
						type: 'POST',
						url: '/journal_issues',
						dataType: 'JSON',
						data: { journal_issue: { journal_id: journal_id, volume: volume, issue_number: issue_number, date: date, special_date: special_date, url: url } },
						success: function (data) {
							$(".journal_auto").val("");
							$(".journal_auto_id").val("");
							$("#resource_journal_issue_attributes_volume").val("");
							$("#resource_journal_issue_attributes_issue_number").val("");
							$("#resource_journal_issue_attributes_special_date").val("");
							$("#resource_journal_issue_attributes_url").val("");
							if (data.special_date.length > 0) {
								$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.special_date + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
							} else {
								$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.issue_number + ", Volume: " + data.volume + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
							}
							$(".j-special").find("option:gt(0)").remove();
							$(".j-issues").find("option:gt(0)").remove();
						}
					});
				}
			});
		} else {
			if ($(".j-issues option:selected").text() != " ") {
				var jId = $(".j-issues option:selected").val();
				var jText = $(".j-issues option:selected").text();
				$("<li data-id='" + jId + "'>" + journal_name + ", <dt>" + jText + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + jId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + jId + "'>");
				$(".journal_auto").val("");
				$(".journal_auto_id").val("");
				$(".j-special").find("option:gt(0)").remove();
				$(".j-issues").find("option:gt(0)").remove();
			} else if ($(".j-special option:selected").text() != " ") {
				var jId = $(".j-special option:selected").val();
				var jText = $(".j-special option:selected").text();
				$("<li data-id='" + jId + "'>" + journal_name + ", <dt>" + jText + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + jId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + jId + "'>");
				$(".journal_auto").val("");
				$(".journal_auto_id").val("");
				$(".j-special").find("option:gt(0)").remove();
				$(".j-issues").find("option:gt(0)").remove();
			} else {
				var journal_id = $(".journal_auto_id").val();
				$.ajax({
					type: 'POST',
					url: '/journal_issues',
					dataType: 'JSON',
					data: { journal_issue: { journal_id: journal_id, volume: volume, issue_number: issue_number, date: date, special_date: special_date, url: url } },
					success: function (data) {
						$(".journal_auto").val("");
						$(".journal_auto_id").val("");
						$("#resource_journal_issue_attributes_volume").val("");
						$("#resource_journal_issue_attributes_issue_number").val("");
						$("#resource_journal_issue_attributes_special_date").val("");
						$("#resource_journal_issue_attributes_url").val("");
						if (data.special_date.length > 0) {
							$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.special_date + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
						} else {
							$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.issue_number + ", Volume: " + data.volume + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
						}
						$(".j-special").find("option:gt(0)").remove();
						$(".j-issues").find("option:gt(0)").remove();
					}
				});
			}
		}


	});
	//journal issue on edit page
	$(".journal-issue-main").on("click", ".jissue-addition-edit", function () {
		var journal_id_original = $(".journal_auto_id").val();
		var journal_name = $(".journal_auto").val();
		var resource_id = $(".role-input-wrapper").attr("data-id");
		var journal_id = $(".journal_auto_id").val();
		var volume = $("#resource_journal_issue_attributes_volume").val();
		var issue_number = $("#resource_journal_issue_attributes_issue_number").val();
		var date = $("#resource_journal_issue_attributes_date_1i").val() + "/" + $("#resource_journal_issue_attributes_date_2i").val() + "/" + $("#resource_journal_issue_attributes_date_3i").val();
		var special_date = $("#resource_journal_issue_attributes_special_date").val();
		var url = $("#resource_journal_issue_attributes_url").val();
		if (journal_id_original == "") {
			$.ajax({
				type: 'POST',
				url: '/journals',
				dataType: 'JSON',
				data: { journal: { name: journal_name } },
				success: function (data) {
					$(".journal_auto_id").val(data.id);
					var journal_id = $(".journal_auto_id").val();
					$.ajax({
						type: 'POST',
						url: '/journal_issues',
						dataType: 'JSON',
						data: { journal_issue: { journal_id: journal_id, volume: volume, issue_number: issue_number, date: date, special_date: special_date, url: url } },
						success: function (data) {
							$(".journal_auto").val("");
							$(".journal_auto_id").val("");
							$("#resource_journal_issue_attributes_volume").val("");
							$("#resource_journal_issue_attributes_issue_number").val("");
							$("#resource_journal_issue_attributes_special_date").val("");
							$("#resource_journal_issue_attributes_url").val("");
							if (data.special_date.length > 0) {
								$("<li data-id='" + data.id + "'>" + journal_name + ",<dt>" + "Issue: " + data.special_date + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
							} else {
								$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.issue_number + ", Volume: " + data.volume + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
							}
							$(".j-special").find("option:gt(0)").remove();
							$(".j-issues").find("option:gt(0)").remove();
						}
					});
				}
			});
		} else {
			var journal_id = $(".journal_auto_id").val();
			if ($(".j-issues option:selected").text() != " ") {
				var jId = $(".j-issues option:selected").val();
				var jText = $(".j-issues option:selected").text();
				$("<li data-id='" + jId + "'>" + journal_name + ", <dt>" + jText + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + jId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + jId + "'>");
				$(".journal_auto").val("");
				$(".journal_auto_id").val("");
				$(".j-special").find("option:gt(0)").remove();
				$(".j-issues").find("option:gt(0)").remove();
			} else if ($(".j-special option:selected").text() != " ") {
				var jId = $(".j-special option:selected").val();
				var jText = $(".j-special option:selected").text();
				$("<li data-id='" + jId + "'>" + journal_name + ", <dt>" + jText + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + jId + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + jId + "'>");
				$(".journal_auto").val("");
				$(".journal_auto_id").val("");
				$(".j-special").find("option:gt(0)").remove();
				$(".j-issues").find("option:gt(0)").remove();
			} else {
				$.ajax({
					type: 'POST',
					url: '/journal_issues',
					dataType: 'JSON',
					data: { journal_issue: { journal_id: journal_id, volume: volume, issue_number: issue_number, date: date, special_date: special_date, url: url } },
					success: function (data) {
						$(".journal_auto").val("");
						$(".journal_auto_id").val("");
						$("#resource_journal_issue_attributes_volume").val("");
						$("#resource_journal_issue_attributes_issue_number").val("");
						$("#resource_journal_issue_attributes_special_date").val("");
						$("#resource_journal_issue_attributes_url").val("");
						if (data.special_date.length > 0) {
							$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.special_date + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
						} else {
							$("<li data-id='" + data.id + "'>" + journal_name + ", <dt>" + "Issue: " + data.issue_number + ", Volume: " + data.volume + " </dt><dd><a class='text-danger' style='display:inline' onclick='removeClosest(this, \"[data-id]\");'><span class='fa fa-remove'></span></a><a data-remote='true' href='/journal_issues/" + data.id + "/edit'><i class='fa fa-edit'></i></a></dd></li>").appendTo(".res-j-issue").append("<input type='hidden' name='resource[journal_issue_id_num]' id='resource_journal_issue_id' value='" + data.id + "'>");
						}
						$(".j-special").find("option:gt(0)").remove();
						$(".j-issues").find("option:gt(0)").remove();
					}
				});
			}

		}


	});
	// j issue select/hide
	$(".journal-issue-main").on("change", ".j-issues", function () {
		if ($(".j-issues option:selected").text() != " ") {
			$(".j-special").attr("disabled", true);
			$(".j_issue_creation").hide();
		} else {
			$(".j-special").attr("disabled", false);
			$(".j_issue_creation").show();
		}
	});

	$(".journal-issue-main").on("change", ".j-special", function () {
		if ($(".j-special option:selected").text() != " ") {
			$(".j-issues").attr("disabled", true);
			$(".j_issue_creation").hide();
		} else {
			$(".j-issues").attr("disabled", false);
			$(".j_issue_creation").show();
		}
		$(".j_issue_creation").hide();
	});

	//modals for resource form
	$("#modal-window").on("click", ".update-author-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".authors-form").attr("data-id");
		var first = $("#author_first_name").val();
		var last = $("#author_last_name").val();
		var affil = $("#author_affiliation").val();
		$.ajax({
			type: "POST",
			url: "/authors/" + auth_id,
			dataType: "JSON",
			data: { author: { first_name: first, last_name: last, affiliation: affil } },
			success: function (data) {
				$("#modal-window").modal('toggle');
				var listing = $(".author_listing").find('[data-id=' + data.id + ']').find("dd:first");
				$(listing).text(last + ", " + first);
			}
		});
	});

	$("#modal-window-pub_id").on("click", ".update-pub-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".pub-form").attr("data-id");
		var identifier = $("#publication_identifier_identifier").val();
		var kind = $("#publication_identifier_kind").val();
		$.ajax({
			type: "POST",
			url: "/publication_identifiers/" + auth_id,
			dataType: "JSON",
			data: { publication_identifier: { identifier: identifier, kind: kind } },
			success: function (data) {
				$("#modal-window-pub_id").modal('toggle');
				var listing = $(".pub-list").find('[data-id=' + data.id + ']').find("dt:first");
				$(listing).text(kind + ": " + identifier);
			}
		});
	});

	$("#modal-window-topics").on("click", ".update-topic-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".topic-form").attr("data-id");
		var name = $("#topic_name").val();
		$.ajax({
			type: "POST",
			url: "/topics/" + auth_id,
			dataType: "JSON",
			data: { topic: { name: name } },
			success: function (data) {
				$("#modal-window-topics").modal('toggle');
				var listing = $(".topics-list").find('[data-id=' + data.id + ']').find("dt:first");
				$(listing).text(name);
			}
		});
	});

	$("#modal-window-j_issue").on("click", ".update-jissue-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".authors-form").attr("data-id");
		var volume = $("#journal_issue_volume").val();
		var date = $("#journal_issue_date_1i").val() + "/" + $("#journal_issue_date_2i").val() + "/" + $("#journal_issue_date_3i").val();
		var issue_number = $("#journal_issue_issue_number").val();
		var special_date = $("#journal_issue_special_date").val();
		var url = $("journal_issue_url").val();
		$.ajax({
			type: "POST",
			url: "/journal_issues/" + auth_id,
			dataType: "JSON",
			data: { journal_issue: { volume: volume, issue_number: issue_number, date: date, special_date: special_date, url: url } },
			success: function (data) {
				$("#modal-window-j_issue").modal('toggle');
				var listing = $(".res-j-issue").find('[data-id=' + data.id + ']').find("dt:first");
				if (data.special_date.length > 0) {
					$(listing).text("Issue: " + special_date);
				} else {
					$(listing).text("Volume: " + volume + ", Issue: " + issue_number);
				}
			}
		});
	});

	$("#modal-window-locations").on("click", ".update-location-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".location-form").attr("data-id");
		var name = $("#location_name").val();
		$.ajax({
			type: "POST",
			url: "/locations/" + auth_id,
			dataType: "JSON",
			data: { location: { name: name } },
			success: function (data) {
				$("#modal-window-locations").modal('toggle');
				var listing = $(".locations-list").find('[data-id=' + data.id + ']').find("dt:first");
				$(listing).text(name);
			}
		});
	});

	$("#modal-window-ext").on("click", ".update-ext-submit", function (event) {
		event.preventDefault();
		var auth_id = $(".ext-form").attr("data-id");
		var name = $("#external_resource_name").val();
		var kind = $("#external_resource_kind").val();
		var url = $("#external_resource_url").val();
		var res = $("#external_resource_resource_id").val();
		$.ajax({
			type: "POST",
			url: "/external_resources/" + auth_id,
			dataType: "JSON",
			data: { external_resource: { name: name, kind: kind, url: url, resource_id: res } },
			success: function (data) {
				$("#modal-window-ext").modal('toggle');
				var listing = $(".ext-list").find('[data-id=' + data.id + ']').find("dt:first");
				$(listing).text(name);
			}
		});
	});
	$(function () {
		$('[data-toggle="popover"]').popover()
	})
});




