/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Initialize rails/ujs. This will ensure that the jQuery instance available
// within THIS PACK auto-injects the Rails CSRF header.
// Note: This will not apply to uses of jQuery from outside of the pack!
require("@rails/ujs").start();
// Initialize active storage client-side JS
require("@rails/activestorage").start();

import 'bootstrap';
import 'blacklight-frontend/app/assets/javascripts/blacklight/blacklight';
import '@ungap/url-search-params';

import 'font-awesome/css/font-awesome.css';
import "./application.scss"; // prompts webpack to include css packs

import '../src/ciao/ciao.js'; // editor functions that must share CSRF from rails/ujs
import "../stylesheets/ciao/ciao.scss";

require.context('../images', true);

window.$ = $;
global.$ = $;

window.removeClosest = function(ele, selector) {
  $(ele).closest(selector).remove();
}

$(document).ready(function() {
  $('.dropdown-toggle').dropdown();
});